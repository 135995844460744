import {fetchRequest} from "../shared/fetch-request";
import 'choices.js/public/assets/styles/choices.min.css';
import {
    initAmount,
    initDeletion,
    initWeight,
    updateOrder,
} from "../shared/order-manager";

let cartElement = null;

export function initCart() {
    cartElement = document.querySelector('#div-cart');
    if (cartElement) {
        initWeight(_updateOrder);
        initAmount(_updateOrder);
        initDeletion(_updateOrder);
    }
}

export function cartProduct(uuid, productId, amount, weight = '', callbackFunction = null) {
    fetchRequest(
        '/cart/add',
        'POST',
        {
            uuid: uuid,
            id: productId,
            amount: amount,
            weight: weight,
        },
        data => {
            if (callbackFunction) {
                callbackFunction(data);
            }
        },
        error => {
            console.error(error);
        }
    );
}

function _updateOrder() {
    updateOrder('/cart/update');
}