export function initSearch() {
    const form = document.querySelector('#form-search');
    if (form) {
        function _submit() {
            const searchText = form.querySelector('input[type=text]').value.trim();
            if (searchText !== '') {
                form.submit();
            }
        }

        form.addEventListener('keypress', ev => {
            const key = ev.charCode || ev.keyCode || 0;
            if (key === 13) {
                ev.preventDefault();
                _submit();
            }
        });
        const btn = form.querySelector('#btn-search');
        if (btn) {
            btn.addEventListener('click', () => {
                _submit();
            });
        }
    }
}