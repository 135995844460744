import Choices from "choices.js";
import dayjs from "dayjs";
import {Datepicker} from 'vanillajs-datepicker';
import uk from "/node_modules/vanillajs-datepicker/js/i18n/locales/uk";
import {getChoicesOptions} from "./choices";
import {fetchRequest} from "./fetch-request";

Object.assign(Datepicker.locales, uk);

const daysRules = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
};

let deliveryDateSelf = null;
let deliveryTimeSelfSelect = null;
let deliveryDateSelfDatePicker = null;

let choicesSelectWeight = {};
let choicesSelectWeightType = {};

export function initWeight(changeCallback = null) {
    const options = getChoicesOptions();
    options.shouldSort = false;
    options.searchEnabled = false;

    function _updateWeight(productUuid) {
        const parent = document.querySelector('#div-product-' + productUuid);
        if (parent) {
            let weight = choicesSelectWeight[productUuid].getValue(false).value;
            if (weight === '-1') {
                // custom option
                weight = parent.querySelector('.input-product-weight-number').value
                    + ' '
                    + choicesSelectWeightType[productUuid].getValue(false).value
            }
            parent.setAttribute('data-weight', weight);
            if (changeCallback) {
                changeCallback();
            }
        }
    }

    document.querySelectorAll('.select-product-weight').forEach(el => {
        const parent = el.closest('.div-product');
        const productUuid = parent.getAttribute('data-uuid');

        choicesSelectWeight[productUuid] = new Choices(el, options);

        el.addEventListener('change', ev => {
            const customElement = parent.querySelector('.div-product-weight-custom');
            if (ev.detail.value === '-1') {
                customElement.classList.remove('d-none');
                parent.querySelector('.input-product-weight-number').value = 1;
                parent.querySelector('.input-product-weight-number').focus();
            } else {
                customElement.classList.add('d-none');
            }
            _updateWeight(productUuid);
        });

        const weightType = parent.querySelector('.select-product-weight-type');
        choicesSelectWeightType[productUuid] = new Choices(
            weightType,
            options
        );
        weightType.addEventListener('change', () => {
            _updateWeight(productUuid);
        });

        const weightNumber = parent.querySelector('.input-product-weight-number');
        ['change', 'keyup'].forEach(type => {
            weightNumber.addEventListener(type, () => {
                _updateWeight(productUuid);
            });
        });
    });
}

export function initAmount(changeCallback = null) {
    document.querySelectorAll('.input-product-amount').forEach(el => {
        const parent = el.closest('.div-product');

        function _updateAmount(value) {
            value = getCorrectAmount(value);
            parent.setAttribute('data-amount', value);
            if (changeCallback) {
                changeCallback();
            }
        }

        ['change', 'keyup'].forEach(type => {
            el.addEventListener(type, () => {
                _updateAmount(el.value);
            });
        });
        const dec = parent.querySelector('.dec');
        if (dec) {
            dec.addEventListener('click', () => {
                const value = getCorrectAmount(el.value);
                _updateAmount(Math.max(parseInt(value) - 1, 0));
            });
        }
        const inc = parent.querySelector('.inc');
        if (inc) {
            inc.addEventListener('click', () => {
                const value = getCorrectAmount(el.value);
                _updateAmount(parseInt(value) + 1);
            });
        }
    });
}

export function initDeletion(changeCallback = null) {
    document.querySelectorAll('.btn-product-remove').forEach(el => {
        const parent = el.closest('.div-product');

        function _resetAmount() {
            parent.setAttribute('data-amount', -1);
            if (changeCallback) {
                changeCallback();
            }
        }

        el.addEventListener('click', e => {
            e.preventDefault();
            // todo: use swal?
            _resetAmount();
        });
    });
}

export function getDelivery() {
    const element = document.querySelector('input[name=delivery]:checked');
    if (element) {
        return element.value;
    }
    return 1; // default value
}

export function initDelivery(callback = null) {
    document.querySelectorAll('input[name="delivery"]').forEach(el => {
        el.addEventListener('change', () => {
            _toggleDeliveryFields(el, callback);
        });
    });

    _toggleDeliveryFields(document.querySelector('input[name="delivery"]:checked'));

    const deliveryDateKyiv = document.querySelector('input[name="delivery-date-kyiv"]');
    if (deliveryDateKyiv) {
        let minDate = dayjs();
        if (deliveryDateKyiv.getAttribute('data-current-day') === '0') {
            minDate = dayjs().add(1, 'day');
        }
        const disabledDays = [];
        Object.keys(daysRules).forEach(day => {
            if (deliveryDateKyiv.getAttribute('data-' + day) === '0') {
                disabledDays.push(daysRules[day]);
            }
        });
        new Datepicker(deliveryDateKyiv, {
            buttonClass: 'btn',
            language: 'uk',
            daysOfWeekDisabled: disabledDays,
            minDate: deliveryDateKyiv.value !== '' ? null : minDate.toDate(),
        });
    }

    const deliveryTimeKyiv = document.querySelector('select[name="delivery-time-kyiv"]');
    if (deliveryTimeKyiv) {
        const options = getChoicesOptions();
        options.shouldSort = false;
        options.searchEnabled = false;
        new Choices(deliveryTimeKyiv, options);
    }

    deliveryDateSelf = document.querySelector('input[name="delivery-date-self"]');
    if (deliveryDateSelf) {
        let minDate = dayjs();
        const disabledDays = [];
        Object.keys(daysRules).forEach(day => {
            if (deliveryDateSelf.getAttribute('data-' + day).trim() === '') {
                disabledDays.push(daysRules[day]);
            }
        });
        deliveryDateSelfDatePicker = new Datepicker(deliveryDateSelf, {
            buttonClass: 'btn',
            language: 'uk',
            daysOfWeekDisabled: disabledDays,
            minDate: deliveryDateSelf.value !== '' ? null : minDate.toDate(),
        });
        deliveryDateSelf.addEventListener('changeDate', ev => {
            _populateDeliverySelfTime(dayjs(ev.detail.date));
        });
    }

    const deliveryTimeSelf = document.querySelector('select[name="delivery-time-self"]');
    if (deliveryTimeSelf) {
        const options = getChoicesOptions();
        options.shouldSort = false;
        options.searchEnabled = false;
        deliveryTimeSelfSelect = new Choices(deliveryTimeSelf, options);
    }

    if (deliveryDateSelfDatePicker) {
        const selectedSelfDate = deliveryDateSelfDatePicker.getDate();
        if (selectedSelfDate) {
            _populateDeliverySelfTime(dayjs(selectedSelfDate));
        }
    }
}

function _populateDeliverySelfTime(selectedDate = null) {
    const selectedDayOfWeek = parseInt(selectedDate.format('d'));
    const item = Object.entries(daysRules).find(el => el[1] === selectedDayOfWeek);
    if (item && deliveryTimeSelfSelect) {
        const range = [];
        const schedule = deliveryDateSelf.getAttribute('data-' + item[0]).split('-');
        const start = schedule[0].trim().split(':');
        const end = schedule[1].trim().split(':');
        const selected = deliveryDateSelf.getAttribute('data-selected');
        // create range
        for (let hour = parseInt(start[0]); hour <= parseInt(end[0]); hour++) {
            let rangeArr = [];
            // from
            if (hour === parseInt(start[0])) {
                rangeArr.push(schedule[0].trim());
            } else {
                rangeArr.push(hour + ':00');
            }
            // to
            if (hour === parseInt(end[0])) {
                rangeArr.push(schedule[1].trim());
            } else {
                rangeArr.push((hour + 1) + ':00');
            }
            const rangeString = rangeArr.join(' - ');
            range.push({
                label: rangeString,
                value: rangeString,
                selected: rangeString === selected,
            });
        }
        deliveryTimeSelfSelect.removeActiveItems();
        deliveryTimeSelfSelect.clearChoices();
        deliveryTimeSelfSelect.setChoices(range);
    }
}

function _toggleDeliveryFields(el, callback = null) {
    document.querySelectorAll('.div-delivery').forEach(dd => {
        dd.classList.add('d-none');
    });
    document.querySelectorAll('.div-' + el.getAttribute('id')).forEach(dd => {
        dd.classList.remove('d-none');
    });
    if (callback) {
        callback();
    }
}

export function renderDataAfterAddingToCart(data) {
    ['subtotal', 'amount', 'total', 'delivery'].forEach(type => {
        if (data[type] !== undefined) {
            document.querySelectorAll('.div-price-' + type).forEach(el => {
                el.textContent = data[type];
            });
        }
    });
}

export function renderDataAfterUpdate(data) {
    const productsExist = [];
    data.products.forEach(p => {
        const subtotal = document.querySelector('.div-subtotal-' + p.uuid);
        if (subtotal) {
            subtotal.textContent = p.totalPrice;
        }
        productsExist.push(p.uuid);
    });
    document.querySelectorAll('.div-product').forEach(el => {
        const uuid = el.getAttribute('data-uuid');
        if (!productsExist.includes(uuid)) {
            el.remove();
        }
    });
}

export function updateOrder(url, additional = null, callback = null) {
    const delivery = getDelivery();

    fetchRequest(
        url,
        'POST',
        {
            data: getProducts(),
            delivery: delivery,
            additional: additional !== null ? additional : '',
        },
        data => {
            renderDataAfterAddingToCart(data);
            renderDataAfterUpdate(data);
            if (callback !== null) {
                callback(data);
            }
        },
        error => {
            console.error(error);
        }
    );
}

export function getProducts() {
    const data = [];
    document.querySelectorAll('.div-product').forEach(el => {
        data.push({
            id: el.getAttribute('data-id'),
            uuid: el.getAttribute('data-uuid'),
            weight: el.getAttribute('data-weight'),
            amount: el.getAttribute('data-amount')
        });
    });
    return data;
}

export function getCorrectAmount(value) {
    return value === '' ? 0 : value;
}