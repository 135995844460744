import 'vanillajs-datepicker/css/datepicker-bs4.css';
import 'choices.js/public/assets/styles/choices.min.css';
import {initDelivery, updateOrder} from "../shared/order-manager";
import {generalFormRequest} from "./helper";

let form;

export function initCheckout() {
    form = document.querySelector('#form-checkout');
    if (form) {
        initDelivery(_updateOrder);

        const btn = form.querySelector('#btn-checkout');
        if (btn) {
            generalFormRequest(form, btn);
        }
    }
}

function _updateOrder() {
    updateOrder('/cart/update');
}