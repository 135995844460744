export function fetchRequest(url, method, data = {}, successCallback = null, errorCallback = null) {
    method = method.toUpperCase();
    data.ajax = 1;
    let dataToSend = null;
    const csrfToken = getCSRFToken();
    if (method === 'POST' || method === 'PUT') {
        const params = new FormData();
        for (let key in data) {
            if (typeof data[key] === 'object' && Array.isArray(data[key])) {
                data[key].forEach(val => {
                    if (typeof val === 'object') {
                        val = JSON.stringify(val);
                    }
                    params.append(key + '[]', val);
                });
            } else if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
                params.append(key, JSON.stringify(data[key]));
            } else {
                params.append(key, data[key]);
            }
        }
        // add csrf token
        if (csrfToken) {
            params.append('_token', csrfToken);
        }
        dataToSend = params;
    }
    if (method === 'GET' || method === 'DELETE') {
        if (csrfToken && method === 'DELETE') {
            data._token = csrfToken;
        }
        const transformData = data => {
            let dataSize = Object.keys(data).length;
            let initial = '?';

            Object.keys(data).map((key, index) => {
                initial = initial.concat(`${key}=${data[key]}`);
                index !== dataSize - 1 && (initial = initial.concat('&'));
            });
            return initial;
        };
        dataToSend = transformData(data);
    }
    const init = {
        method: method,
    };
    if (dataToSend) {
        if (method === 'POST' || method === 'PUT') {
            init.body = dataToSend;
        }
        if (method === 'GET' || method === 'DELETE') {
            url = url + dataToSend;
        }
    }
    fetch(url, init)
        .then(resp => {
            if (!resp.ok) {
                return Promise.reject(resp);
            }
            return resp.json();
        })
        .then(resp => {
            if (successCallback !== null) {
                successCallback(resp);
            }
        })
        .catch(error => {
            if (error.status === 401) {
                // redirect to login page
                error.json().then(jsonError => {
                    window.location.href = jsonError.link;
                }).catch(genericError => {
                    console.log('generic error');
                    console.error(genericError);
                });
            } else if (typeof error.json === "function") {
                error.json().then(jsonError => {
                    if (errorCallback !== null) {
                        errorCallback(jsonError);
                    }
                }).catch(genericError => {
                    console.log('generic error');
                    console.error(genericError);
                });
            } else {
                console.log('other error');
                console.error(error);
            }
        });
}

export function getCSRFToken() {
    let csrfToken = '';
    for (const el of document.getElementsByTagName('meta')) {
        if (el.getAttribute('name') === 'csrf-token') {
            csrfToken = el.getAttribute('content');
        }
    }
    return csrfToken;
}