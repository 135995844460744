import {getTranslator} from "./translator";

export function getChoicesOptions() {
    const t = getTranslator();
    return {
        loadingText: t.gettext('Loading...'),
        noResultsText: t.gettext('No results found'),
        noChoicesText: t.gettext('No choices to choose from'),
        itemSelectText: t.gettext('Press to select'),
        uniqueItemText: t.gettext('Only unique values can be added'),
        customAddItemText: t.gettext('Only values matching specific conditions can be added'),
        searchPlaceholderValue: t.gettext('Search...'),
        addItemText: (value) => {
            return t.gettext('Press Enter to add %1', `<b>"$value"</b>`);
        },
        maxItemText: (maxItemCount) => {
            return t.gettext('Only %1 values can be added', maxItemCount);
        },
        fuseOptions: {
            threshold: 0.0,
            ignoreLocation: true,
        },
        position: 'bottom',
    }
}