import Translator from 'gettext.js';
import {fetchRequest} from "./fetch-request";

let t = null;

export function getLang() {
    return document.getElementsByTagName('html')[0].getAttribute('lang');
}

export function loadTranslator() {
    return new Promise((resolve, reject) => {
        if (t === null) {
            const lang = getLang();
            if (lang !== 'en') {
                fetchRequest(
                    '/translator',
                    'POST',
                    {},
                    data => {
                        t = new Translator();
                        t.setLocale(data.locale);
                        t.setMessages(
                            data.domain,
                            data.locale,
                            data.messages,
                            data.pluralForms
                        );
                        resolve();
                    },
                );
            } else {
                t = new Translator({});
                resolve();
            }
        } else {
            resolve();
        }
    });
}

export function getTranslator() {
    return t;
}