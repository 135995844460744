import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import './original.css';
import './style.scss';
import './original';
import {loadTranslator} from "../shared/translator";
import {initShop} from "./shop";
import {initOffice} from "./office";
import {initCart} from "./cart";
import {initCheckout} from "./checkout";
import {initSession} from "../shared/session";
import {initSearch} from "./search";
import {initInputNumber} from "../shared/input-number";
//import 'progressive-image.js/dist/progressive-image.css';
import 'progressive-image.js/dist/progressive-image';
import {isMobile} from "../shared/device";

loadTranslator().then(() => {
    if (isMobile()) {
        const body = document.querySelector('body');
        body.classList.add('mobile');
    }
    initCart();
    initCheckout();
    initInputNumber();
    initOffice();
    initSearch();
    initSession();
    initShop();
});
