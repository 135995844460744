import {fetchRequest} from "../shared/fetch-request";
import {generalFormRequest} from "./helper";

let ordersCurrentPage = 1;

export function initOffice() {
    _initLogin();
    _initOrders();
    _initProfile();
    _initRegistration();
}

function _initOrders() {
    const ordersElement = document.querySelector('#div-orders-results');
    if (ordersElement) {
        function _loadOrders() {
            fetchRequest(
                ordersElement.getAttribute('data-url'),
                ordersElement.getAttribute('data-method'),
                {
                    page: ordersCurrentPage,
                },
                data => {
                    const countElement = document.querySelector('#div-orders-count');
                    if (countElement) {
                        countElement.textContent = data.total;
                    }

                    ordersElement.innerHTML = data.ordersHtml;

                    const pagerElement = document.querySelector('#div-orders-pager');
                    if (pagerElement) {
                        pagerElement.innerHTML = data.pagerHtml;
                        // init links
                        pagerElement.querySelectorAll('a[data-page]').forEach(el => {
                            el.addEventListener('click', () => {
                                ordersCurrentPage = parseInt(el.getAttribute('data-page'));
                                _loadOrders();
                            })
                        });
                    }
                },
            );
        }

        _loadOrders();
    }
}

function _initRegistration() {
    const form = document.querySelector('#form-office-registration');
    if (form) {
        const btn = form.querySelector('#btn-office-registration');
        if (btn) {
            generalFormRequest(form, btn);
        }
    }
}

function _initLogin() {
    const form = document.querySelector('#form-office-login');
    if (form) {
        const btn = form.querySelector('#btn-office-login');
        if (btn) {
            generalFormRequest(form, btn);
        }
    }
}

function _initProfile() {
    const form = document.querySelector('#form-office-profile');
    if (form) {
        const btn = form.querySelector('#btn-office-profile');
        if (btn) {
            generalFormRequest(form, btn);
        }
    }
}
