import AWN from "awesome-notifications";
import 'awesome-notifications/dist/style.css';
import {getTranslator} from "../shared/translator";
import {fetchRequest} from "../shared/fetch-request";

export function clearFormErrors(parent = document) {
    parent.querySelectorAll('.div-error').forEach(el => {
        el.textContent = '';
    });
}

export function fillFormErrors(errors, parent = document) {
    errors.forEach(err => {
        const element = parent.querySelector('.div-' + err.field);
        if (element) {
            const hint = element.querySelector('.div-error');
            if (hint) {
                hint.textContent = err.message;
            }
        }
    });
}

export function generalFormRequest(form, btn) {
    btn.addEventListener('click', () => {
        clearFormErrors();
        btn.disabled = true;
        const spinner = btn.querySelector('.btn-spinner');
        if (spinner) {
            spinner.classList.remove('invisible');
        }
        const data = {};
        const formData = new FormData(form);
        for (const [key, value] of formData.entries()) {
            data[key] = value;
        }
        fetchRequest(
            form.getAttribute('action'),
            form.getAttribute('method'),
            data,
            data => {
                btn.disabled = false;
                if (spinner) {
                    spinner.classList.add('invisible');
                }
                window.location.href = data.link;
            },
            error => {
                btn.disabled = false;
                if (spinner) {
                    spinner.classList.add('invisible');
                }
                console.error(error);
                if (error.errors !== undefined) {
                    fillFormErrors(error.errors);
                }
            }
        );
    });
}

export function cartAddedNotification() {
    const t = getTranslator();
    // show notification
    const notifier = new AWN({
        durations: {
            global: 1500,
        },
        labels: {
            success: t.gettext('Added'),
        },
        position: 'top-right',
        icons: {success: 'shopping-bag'},
        maxNotifications: 1,
        animationDuration: 500,
    });
    notifier.success(t.gettext('Product successfully added to the cart'));
}