import {fetchRequest} from "../shared/fetch-request";
import {cartProduct} from "./cart";
import {initAmount, initWeight, renderDataAfterAddingToCart} from "../shared/order-manager";
import {cartAddedNotification} from "./helper";

let currentPage = 1;
let sort = 0;

function _loadProducts() {
    fetchRequest(
        '/shop/products/load',
        'POST',
        {
            page: currentPage,
            sort: sort,
            category: document.querySelector('#categoryId').value,
            search: document.querySelector('#form-search')
                .querySelector('input[type="text"]').value.trim(),
        },
        data => {
            const countElement = document.querySelector('#div-products-count');
            if (countElement) {
                countElement.textContent = data.total;
            }
            const resultsElement = document.querySelector('#div-product-results');
            if (resultsElement) {
                resultsElement.innerHTML = data.productsHtml;
            }
            _initShopProductListCartClick();
            const pagerElement = document.querySelector('#div-product-pager');
            if (pagerElement) {
                pagerElement.innerHTML = data.pagerHtml;
                // init links
                pagerElement.querySelectorAll('a[data-page]').forEach(el => {
                    el.addEventListener('click', () => {
                        currentPage = parseInt(el.getAttribute('data-page'));
                        _loadProducts();
                        const topContainer = $(".div-shop-products-container");
                        $('html,body').animate({scrollTop: topContainer.offset().top},'slow');
                    })
                });
            }
        },
    );
}

function _initShopProductListCartClick() {
    document.querySelectorAll('.btn-list-add-product-to-cart').forEach(el => {
        el.addEventListener('click', () => {
            cartProduct(
                el.getAttribute('data-uuid'),
                el.getAttribute('data-id'),
                el.getAttribute('data-amount'),
                el.getAttribute('data-weight'),
                data => {
                    renderDataAfterAddingToCart(data);
                    cartAddedNotification();
                }
            );
        });
    });
}

export function initShop() {
    const productsElement = document.querySelector('#div-product-results');
    if (productsElement) {
        _loadProducts();
    }
    const selectSort = document.querySelector('#select-sort');
    if (selectSort) {
        const jqSort = $('#select-sort');
        jqSort.change(el => {
            sort = parseInt(jqSort.val());
            _loadProducts();
        });
        jqSort.niceSelect();
    }
    _initShopProductListCartClick();

    const productElement = document.querySelector('.div-product-one');
    if (productElement) {
        initWeight();
        initAmount();

        const btn = productElement.querySelector('.btn-one-add-product-to-cart');
        if (btn) {
            btn.addEventListener('click', () => {
                if (parseInt(productElement.getAttribute('data-amount')) > 0) {
                    cartProduct(
                        productElement.getAttribute('data-uuid'),
                        productElement.getAttribute('data-id'),
                        productElement.getAttribute('data-amount'),
                        productElement.getAttribute('data-weight'),
                        data => {
                            renderDataAfterAddingToCart(data);
                            cartAddedNotification();
                        }
                    );
                }
            });
        }
    }

    document.querySelectorAll('.a-category-menu-parent').forEach(el => {
        el.addEventListener('click', ev => {
            window.location.href = el.getAttribute('data-link');
        });
    });
}